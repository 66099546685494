<template>
  <div class="leaflets-container">
    <Breadcrumb :items="breadCrumbs" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <CategoryTitle :category="category" />
      <leaflets-slider
        v-if="leaflet_types"
        :parentLeafletTypeId="leaflet_types"
      />
    </v-container>
    <category-block
      :target="category"
      position="product1"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import categoryMixin from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import LeafletsSlider from "@/components/leaflet/LeafletsSlider.vue";

export default {
  name: "Leaflets",
  components: {
    Breadcrumb,
    CategoryTitle,
    LeafletsSlider
  },
  mixins: [categoryMixin],
  data() {
    return {
      breadCrumbs: [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { text: "Volantini" }
      ],
      leaflet_types: null
    };
  },
  async mounted() {
    this.leaflet_types = this.$route.query.leaflet_types.split(",");
  }
};
</script>
